import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'Coupon/:id',
    loadChildren: () => import('../../components/coupon/coupon.module').then(m => m.InvoiceModule),
    data: {
      breadcrumb: "Coupon"
    }
  }
];