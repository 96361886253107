import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  constructor() { }
}

export class ObjectCopier<T> {
  public copyWith(modifyObject: Partial<T>): T {
    return Object.assign(Object.create(this.constructor.prototype), this, modifyObject);
  }
}
export class SigninModel extends ObjectCopier<SigninModel> {
  constructor(
    public readonly emplCode: string,
    public readonly password: string
  ) {
    super();
  }
}
export class SigninResModel extends ObjectCopier<SigninResModel> {
  constructor(
    public readonly data: SigninResdataModel,
    public readonly token: string,
    public readonly expire: string,
  ) {
    super();
  }
}
export class SigninResdataModel extends ObjectCopier<SigninResdataModel> {
  constructor(
    public readonly emplCode: string,
    public readonly emplName: string,
    public readonly emplPhone: string,
    public readonly userPWD: string,
    public readonly emplStat: string,
    public readonly emplID: string,
    public readonly company: string,
    public readonly carRegist: string,
    public readonly log: string,
  ) {
    super();
  }
}


export class createcouponreq extends ObjectCopier<createcouponreq> {
  constructor(
    public readonly name: string,
    public readonly wholeName: string,
    public readonly phone: string,
    public readonly subdistrict: string,
    public readonly district: string,
    public readonly province: string,
    public readonly zipCode: string,
    public readonly wholeZoneCode: string,
  ) {
    super();
  }
}


export class createcouponres extends ObjectCopier<createcouponres> {
  constructor(
    public readonly coupon: string
  ) {
    super();
  }
}

export class AddressInfoReply extends ObjectCopier<AddressInfoReply> {
  constructor(
    public readonly id: number,
    public readonly zipCode: string,
    public readonly subdistrict: string,
    public readonly district: string,
    public readonly province: string,
    public readonly wholeZoneCode: string,
  ) {
    super();
  }
}
