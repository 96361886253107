import { Injectable } from '@angular/core';
import { ResultType } from './result_type';
import { AddressInfoReply, SigninModel, SigninResModel, createcouponreq, createcouponres } from './model.service';
import { HttpClient } from '@angular/common/http';
import { UrlService } from './url.service';
@Injectable({
  providedIn: 'root'
})
export class SigninService {

  constructor(private http: HttpClient, private link: UrlService) { }

  public async createcoupon(input: createcouponreq): Promise<createcouponres> {
    let output = await ResultType.invokeThrow<createcouponres, Error>(
      (onResponse) => {
        this.http.post<createcouponres>(
          this.link.baseUrl + '/DN/CreateCoupon',
          input,
          {
            headers: { "ContentType": "application/json" }
          }
        ).subscribe({
          next(data) {
            onResponse(null, data);
          },
          error(msg) {
            onResponse(msg, null);
          }
        })
      }
    );
    return output;
  }
  public async getAddressInfo(): Promise<AddressInfoReply[]> {
    let addresses = await ResultType.invokeThrow<AddressInfoReply[], Error>(
      (onResponse) => {
        this.http.get<AddressInfoReply[]>(
          'https://api.drugnetcenter.com/WholeService/GetAddressInfo',
          {
            headers: { "ContentType": "application/json" }
          }
        ).subscribe({
          next(data) {
            onResponse(null, data);
          },
          error(msg) {
            onResponse(msg, null);
          }
        })
      }
    );
    return addresses;
  }


  public signOut() {
    localStorage.removeItem("ID_OUTHOUSE");
  }
}
