<div>
    <div class="page-wrapper">
        <div class="page-body-wrapper">
            <div class="page-body">
                <main>
                    <router-outlet #o="outlet"></router-outlet>
                </main>
            </div>
        </div>
    </div>
</div>