import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import Swal from 'sweetalert2'
import { doughnutData, pieData } from '../../shared/data/chart';
import { FormBuilder } from '@angular/forms';
import { SigninService } from 'src/app/service/signin.service';
import { AddressInfoReply, createcouponreq } from 'src/app/service/model.service';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig }
  from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [NgbModalConfig, NgbModal],
})
export class DashboardComponent implements OnInit {
  public doughnutData = doughnutData;
  public pieData = pieData;
  constructor(config: NgbModalConfig, private modalService: NgbModal, private fb: FormBuilder, private signinService: SigninService, private router: Router,) {
    config.backdrop = 'static';
    config.keyboard = false;
  }
  options: any;
  bg: string = '';
  title: string = '';
  body: string = '';
  @ViewChild('secondDialog', { static: true }) secondDialog: TemplateRef<any> | undefined;
  async ngOnInit(): Promise<void> {
    try{
      this.options = this.fb.group({
        WholeName: '',
        Name: '',
        Phone: '',
        subdistrict: '',
        district: '',
        province: '',
        zipCode: '',
        wholeZoneCode: ''
      });
      this.addressInfos = await this.signinService.getAddressInfo();
    }catch(e:any){
      this.error(e);
    }

  }
  checkPhone(){
    try{
     let d = Number(this.options.value.Phone);
     if(Number.isNaN(d)){
      return false
     }
      return true
    }catch(e:any){
      return false
    }
  }
  async onSubmit(): Promise<void> {
    if (this.options.value.WholeName == null || this.options.value.WholeName == '' || this.options.value.WholeName == undefined) {
      this.bg = 'red';
      this.title = 'ERROR';
      this.body = `กรุณากรอกข้อมูลให้ครบถ้วน`;
      this.modalService.open(this.secondDialog, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => { });
    } else if (this.options.value.Name == null || this.options.value.Name == '' || this.options.value.Name == undefined) {
      this.bg = 'red';
      this.title = 'ERROR';
      this.body = `กรุณากรอกข้อมูลให้ครบถ้วน`;
      this.modalService.open(this.secondDialog, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => { });
    } else if (this.options.value.Phone == null || this.options.value.Phone == '' || this.options.value.Phone == undefined) {
      this.bg = 'red';
      this.title = 'ERROR';
      this.body = `กรุณากรอกข้อมูลให้ครบถ้วน`;
      this.modalService.open(this.secondDialog, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => { });
    } else if (!(this.options.value.Phone.length>8&&this.options.value.Phone.length<11&&this.checkPhone())) {
      this.bg = 'bule';
      this.title = 'INFO';
      this.body = `กรุณากรอกเบอร์โทรตามรูปแบบ`;
      this.modalService.open(this.secondDialog, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => { });
    } else if ( this.options.value.province == null ||  this.options.value.province == '' ||  this.options.value.province == undefined) {
      this.bg = 'red';
      this.title = 'ERROR';
      this.body = `กรุณากรอกข้อมูลให้ครบถ้วน`;
      this.modalService.open(this.secondDialog, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => { });
    }else {
      try {
        var data = await this.signinService.createcoupon(
          new createcouponreq(
            this.options.value.Name,
            this.options.value.WholeName,
            this.options.value.Phone,
            this.options.value.subdistrict,
            this.options.value.district,
            this.options.value.province,
            this.options.value.zipCode, 
            this.options.value.wholeZoneCode
          )
        );
        let timerInterval;
        await Swal.fire({
          title: "กรุณารอสักครู่",
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const timer = Swal.getPopup().querySelector("b");
            timerInterval = setInterval(() => {
              timer.textContent = `${Swal.getTimerLeft()}`;
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          }
        });
        this.router.navigate([`/Coupon/${data.coupon}`]);
      } catch (e) {
        if (e.status == 415) {
          this.bg = 'red';
          this.title = 'ERROR';
          this.body = `เบอร์โทรซ้ำกรุณาระบุเบอร์โทรใหม่`;
          this.modalService.open(this.secondDialog, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          }, (reason) => {});
        }else if (e.status == 417) {
          this.bg = 'bule';
          this.title = 'INFO';
          this.body = `????`;
          this.modalService.open(this.secondDialog, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          }, (reason) => {});
        } else if (e.status == 0 || e.status == 502) {
          this.bg = 'red';
          this.title = 'ERROR';
          this.body = `ไม่สามารถเชื่อมต่อ Serve ได้`;
          this.modalService.open(this.secondDialog, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          }, (reason) => {});
        }else {
          let code = '';
          if (e.status != null && e.status != undefined && e.status != '') {
            code = `COSE ${e.status}`;
          }
          this.bg = 'red';
          this.title = `ERROR ${code}`;
          this.body = `${e.message}`;
          this.modalService.open(this.secondDialog, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          }, (reason) => {});
        }
      }
    }
  }
  displayStyle = "none";
  style: number = 0;
  openPopup() {
    this.style = 1;
    this.displayStyle = "block";
  }
  closePopup() {
    this.style = 0;
    this.displayStyle = "none";
  }
  searchAddrText: string = '';
  addressInfos: AddressInfoReply[] | [];
  addressInfosSearch: AddressInfoReply[] | [];
  async searchTextChanged($event: any) {
    try {
      if (!this.addressInfos) {
        this.addressInfos = await this.signinService.getAddressInfo();
      }
      this.addressInfosSearch = this.addressInfos.filter((element) => {
        return ((element.subdistrict??'').includes($event) ||
        (element.district??'').includes($event) ||
        (element.province??'').includes($event) ||
        (element.zipCode??'').includes($event));
      });
    } catch (e: any) {
      this.error(e);
    }
  }

  applyAddress(el: AddressInfoReply) {
    this.options.patchValue({ subdistrict: el.subdistrict }, { emitEvent: true });
    this.options.patchValue({ district: el.district }, { emitEvent: true });
    this.options.patchValue({ province: el.province }, { emitEvent: true });
    this.options.patchValue({ zipCode: el.zipCode }, { emitEvent: true });
    this.options.patchValue({ wholeZoneCode: el.wholeZoneCode?.toString() }, { emitEvent: true });
    this.closePopup()
  }
  error(e: any){
     if (e.status == 0 || e.status == 502) {
      this.bg = 'red';
      this.title = 'ERROR';
      this.body = `ไม่สามารถเชื่อมต่อ Serve ได้`;
      this.modalService.open(this.secondDialog, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => {});
    }else {
      let code = '';
      if (e.status != null && e.status != undefined && e.status != '') {
        code = `COSE ${e.status}`;
      }
      this.bg = 'red';
      this.title = `ERROR ${code}`;
      this.body = `${e.message}`;
      this.modalService.open(this.secondDialog, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => {});
    }
  }
}
