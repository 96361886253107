<div class="container-fluid pd-top">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div style='text-align: center; margin-top: -10px;'>
                        <img style="width: 150px;" src='../../../assets/images/dnlo.jpg' />
                    </div>
                    <div class="mt-3" style='text-align: center;'>
                        <canvas width="250px" #barcodeCanvas></canvas>
                    </div>
                    <!-- <div class="mt-3" style='text-align: center;'>
                        <h5>54-568Now</h5>
                    </div> -->
                    <div style='text-align: center;' class="product-buttons mt-5 mb-5">
                        <button type="button" (click)="downloadBarcode()" class=" btn btn-primary me-1">Download
                            QRCode</button>
                    </div>
                    <div
                        style='padding-top:8px; text-align:center; font-size:15px; font-family: Source Sans Pro, Arial, sans-serif;'>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->