import { DecimalPipe } from '@angular/common';
import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TableService } from 'src/app/shared/service/table.service';
import JsBarcode, { Options as jsBarcodeOptions } from 'jsbarcode';
import { saveAs } from 'file-saver';
import { ActivatedRoute, Params } from '@angular/router';
@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
  providers: [TableService, DecimalPipe]
})
export class CouponComponent implements OnInit {
  constructor(private route: ActivatedRoute) { }
  barcode: string = ''
  ngOnInit(): void {
    this.route.params.subscribe((params) => this.prepare(params));
  }
  async prepare(params: Params): Promise<void> {
    this.barcode = params['id'];
    this.generateBarcode(this.barcode);
  }
  public active = 1;
  qrData = 'https://example.com';
  @ViewChild('imageElement') imageElement: ElementRef<HTMLImageElement>;
  @ViewChild('barcodeCanvas', { static: true }) barcodeCanvas: ElementRef<HTMLCanvasElement>;

  downloadImage() {
    const image = this.imageElement.nativeElement;
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0);

    canvas.toBlob(blob => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = this.getFileName(image.src);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, 'image/jpeg');
  }

  getFileName(url: string): string {
    return url.substring(url.lastIndexOf('/') + 1);
  }
  generateBarcode(text: string): void {
    const canvas = this.barcodeCanvas.nativeElement;
    const options: jsBarcodeOptions = {
      format: 'CODE128',
      lineColor: '#3d0077',
      width: 2,
      height: 150,
      displayValue: true
    };
    JsBarcode(canvas, text, options);
  }

  downloadBarcode(): void {
    const canvas = this.barcodeCanvas.nativeElement;
    canvas.toBlob((blob) => {
      if (blob) {
        saveAs(blob, `${this.barcode}.jpg`);
      }
    }, 'image/jpeg');
  }
}
