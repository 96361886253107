<!-- Container-fluid starts-->
<div class="container-fluid pd-top">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <div class="d-flex justify-content-center">
                        <img style="width: 500px; border-radius: 15%;" src="../../../assets/images/dnlo.jpg">
                    </div>
                    <!-- <div class="d-flex justify-content-center">
                        <h5>ลงทะเบียนร้านค้า</h5>
                    </div> -->

                </div>
                <!-- <div class="card-body">
                    <div class="row product-adding">
                        <div class="col-xl-12">
                            <form class="needs-validation">
                                <div class="add-product-form">
                                    <div class="form-group row" [formGroup]="options">
                                        <div class="col-xl-10 col-sm-4 ">
                                            <label for="WholeName" class="mb-2">ชื่อร้านค้า
                                                :</label>
                                        </div>
                                        <div class="col-xl-12 col-sm-7">
                                            <input class="form-control" formControlName="WholeName" name="WholeName"
                                                type="text">
                                        </div>
                                    </div>
                                    <div class="form-group row" [formGroup]="options">
                                        <div class="col-xl-10 col-sm-4">
                                            <label for="Name" class="mb-2">ชื่อ
                                                :</label>
                                        </div>
                                        <div class="col-xl-12 col-sm-7">
                                            <input class="form-control" formControlName="Name" name="Name" type="text">
                                        </div>
                                    </div>
                                    <div class="form-group row" [formGroup]="options">
                                        <div class="col-xl-10 col-sm-4">
                                            <label for="Phone" class="mb-2">เบอร์โทร
                                                : <samp class="t">0812345678</samp></label>
                                        </div>
                                        <div class="col-xl-12 col-sm-7">
                                            <input class="form-control" type="text" formControlName="Phone"
                                                name="Phone">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group row" [formGroup]="options">
                                                <div class="col-xl-10 col-sm-4">
                                                    <label for="subdistrict" class="mb-2">ตำบล
                                                        :</label>
                                                </div>
                                                <div class="col-xl-12 col-sm-7">
                                                    <input class="form-control" type="text"
                                                        formControlName="subdistrict" name="subdistrict" readonly
                                                        (click)="openPopup()">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group row" [formGroup]="options">
                                                <div class="col-xl-10 col-sm-4">
                                                    <label for="district" class="mb-2">อำเภอ
                                                        :</label>
                                                </div>
                                                <div class="col-xl-12 col-sm-7">
                                                    <input class="form-control" type="text" formControlName="district"
                                                        name="district" readonly (click)="openPopup()">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group row" [formGroup]="options">
                                                <div class="col-xl-10 col-sm-4">
                                                    <label for="province" class="mb-2">จังหวัด
                                                        :</label>
                                                </div>
                                                <div class="col-xl-12 col-sm-7">
                                                    <input class="form-control" type="text" formControlName="province"
                                                        name="province" readonly (click)="openPopup()">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group row" [formGroup]="options">
                                                <div class="col-xl-10 col-sm-4">
                                                    <label for="zipCode" class="mb-2">รหัสไปรษณีย์
                                                        :</label>
                                                </div>
                                                <div class="col-xl-12 col-sm-7">
                                                    <input class="form-control" type="text" formControlName="zipCode"
                                                        name="zipCode" readonly (click)="openPopup()">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button class="button-5" (click)="onSubmit()" role="button">ลงทะเบียน</button>
                            </form>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
<ng-template #secondDialog let-c="close" let-d="dismiss">
    <div class="modal-header {{bg}}">
        <h4 class="modal-title {{bg}}" id="modal-basic-title">{{title}}</h4>
    </div>
    <div class="modal-body">
        <p>{{body}}</p>
    </div>
    <div class="modal-footer {{bg}}">
        <button type="button" class="btn btn-outline-dark " (click)="c('Save click')">ปิด</button>
    </div>
</ng-template>
<div tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
    <div *ngIf="style==1" class="bg">
        <article class="modal-container">
            <header class="modal-container-header">
                <h2 class="modal-container-title">
                    ระบุตำแหน่งจากรหัสไปรษณีย์
                </h2>
                <button class="icon-button" (click)="closePopup()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path fill="currentColor"
                            d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                    </svg>
                </button>
            </header>
            <section class="modal-container-body rtf">
                <div class="col-md-12">
                    <input type="text" (ngModelChange)="searchTextChanged($event)" [(ngModel)]="searchAddrText"
                        class="form-control mb-3 rounded-pill pl-3" placeholder="ค้นหา อำเภอ/เขต รหัสไปรษณีย์"
                        role="combobox" aria-expanded="false" aria-controls="options">
                </div>
                <div *ngIf="addressInfos">
                    <div *ngFor="let el of addressInfosSearch; let i = index" (click)="applyAddress(el)"
                        class="d-flex justify-content-center">
                        <button class="button-17" role="button">{{el.subdistrict}} {{el.district}}
                            {{el.province}} {{el.zipCode}}</button>
                    </div>
                </div>
            </section>
        </article>
    </div>
</div>