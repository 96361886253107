import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  public baseUrl: string = "https://open.event.drugnetcenter.com/api";
  public version: string = '0.0.0.0.1';
  constructor() { }
}
